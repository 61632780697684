/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./widget.component";
var styles_WhatsappWidgetComponent = [i0.styles];
var RenderType_WhatsappWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WhatsappWidgetComponent, data: {} });
export { RenderType_WhatsappWidgetComponent as RenderType_WhatsappWidgetComponent };
export function View_WhatsappWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["href", "https://api.whatsapp.com/send?phone=5491136694805&text=Hola,%20me%20gustar\u00EDa%20averiguar%20por%20un%20servicio."], ["rel", "noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "lni-whatsapp"]], null, null, null, null, null))], null, null); }
export function View_WhatsappWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "whatsapp-app", [], null, null, null, View_WhatsappWidgetComponent_0, RenderType_WhatsappWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i2.WhatsappWidgetComponent, [], null, null)], null, null); }
var WhatsappWidgetComponentNgFactory = i1.ɵccf("whatsapp-app", i2.WhatsappWidgetComponent, View_WhatsappWidgetComponent_Host_0, {}, {}, []);
export { WhatsappWidgetComponentNgFactory as WhatsappWidgetComponentNgFactory };
