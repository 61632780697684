import {Component, Input, OnInit} from '@angular/core';
import {ContactformService} from './services/contactform.service';
import {Form} from './vo/form';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {ReCaptchaV3Service} from 'ngx-captcha';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @Input() form: Form = {
    nombre: '',
    asunto: '',
    email: '',
    mensaje: ''
  };

  reCaptcha: any;
  token: any;

  enviar = true;

  constructor(private  contactformService: ContactformService,
              private iziToast: Ng2IzitoastService,
              private reCaptchaV3Service: ReCaptchaV3Service) {}

  ngOnInit() {
    this.reCaptcha = '6LcBt6kUAAAAACH5Q6eUMuDhof065BSdmRbOqAAl';
    this.createRecaptchaResponse();
  }

  /**
   * Creo el token para el reCaptcha.
   */
  createRecaptchaResponse() {
    this.reCaptchaV3Service.execute(this.reCaptcha, 'homepage', (token) => {
      this.token = token;
    }, {
      useGlobalDomain: false
    });
  }

  /**
   * Valido y envio el formulario.
   */
  submitForm(): void {
    // Deshabilito el boton de enviar
    this.enviar = false;

    // Primero valido los datos basicos del formulario
    if (this.datosValidos()) {
      // Envio el e-mail
      this.contactformService.enviarEmail(this.form, this.reCaptcha, this.token)
        .subscribe(
          () => {
            this.iziToast.success({
              message: 'Recibimos tu mensaje, te contestamos en breve',
              position: 'bottomRight'
            });

            // Si se envio, blanqueo el formulario
            this.form = {nombre: '', asunto: '', email: '', mensaje: ''};

            this.enviar = true;
          },
          error => {
            this.loguearError(error);
            this.enviar = true;
          });

      // Reseteo el recaptchaResponse
      this.createRecaptchaResponse();
    } else {
      this.enviar = true;
    }
  }

  private datosValidos() {
    let validos = true;

    // Valido e-mail
    if (this.form.email === '' || !this.emailValido(this.form.email)) {
      this.iziToast.error({
        title: 'Error:',
        message: 'Por favor inserte un e-mail valido',
        position: 'center'
      });

      validos = false;
    }

    // Valido que ponga algo en el msj
    if (this.form.mensaje === '') {
      this.iziToast.error({
        title: 'Error:',
        message: 'Por favor escriba su consulta',
        position: 'center'
      });

      validos = false;
    }

    return validos;
  }

  /**
   * Valido que el email tenga un fromato valido.
   */
  emailValido(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  }

  /**
   * Muestro el mensaje de error
   */
  private loguearError(error: any) {
    // Error default
    let mensaje = 'Error en el servidor';

    // Seteo el mensaje de error del servidor
    if (error != null && error.error != null && error.error.response != null) {
      mensaje = error.error.response;
    }

    // muestro el error en un msj por pantalla
    this.iziToast.error({
      title: 'Error:',
      message: mensaje,
      position: 'center'
    });
  }
}
