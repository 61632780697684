import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Form} from '../vo/form';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';


@Injectable()
export class ContactformService {

  private url = environment.urlEnvioMail;

  // Create constructor to get Http instance
  constructor(private http: HttpClient) {}

  public enviarEmail = (form: Form, recaptcha: string, token: string): Observable<any> => {
    const headers = new HttpHeaders();

    // Url con parametros a enviar
    const request = this.url + '?recaptcha=' + recaptcha + '&token=' + token + '&nombre=' + form.nombre +
      '&email=' + form.email + '&asunto=' + form.asunto + '&mensaje=' + form.mensaje;

    // Ejecuto el envio de mail y logeo el resultado
    return this.http.put(request, null, {headers: headers});
  }
}
