import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {WhatsappWidgetComponent} from './whatsapp/widget.component';

import {Ng2IziToastModule} from 'ng2-izitoast';
import { NgxCaptchaModule } from 'ngx-captcha';
import {FormsModule} from '@angular/forms';
import {ContactformService} from './services/contactform.service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    WhatsappWidgetComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    Ng2IziToastModule,
    NgxCaptchaModule
  ],
  providers: [
    ContactformService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
