<!-- Header Section Start -->
<header id="slider-area">
  <nav class="navbar navbar-expand-md fixed-top scrolling-navbar bg-white">
    <div class="container">
      <a class="navbar-brand" href="index.html">
        <img src="/assets/img/logo.png">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <i class="lni-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto w-100 justify-content-end">
          <li class="nav-item">
            <a class="nav-link page-scroll" href="#slider-area">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" href="#services">Servicios</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" href="#team">Nosotros</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" href="#work">Trabajo</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" href="#google-map-area">Ubicación</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" href="#contact">Contacto</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- Main Carousel Section -->
  <div id="carousel-area">
    <div id="carousel-slider" class="carousel slide carousel-fade" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carousel-slider" data-slide-to="0" class="active"></li>
        <li data-target="#carousel-slider" data-slide-to="1"></li>
        <li data-target="#carousel-slider" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <img src="/assets/img/slider/slider1.jpg" alt="">
          <div class="carousel-caption text-center">
            <h2 class="wow fadeInRight" data-wow-delay="0.2s">Honorarios</h2>
            <h3 class="wow fadeInRight" data-wow-delay="0.4s">Claros y Accesibles</h3>
          </div>
        </div>
        <div class="carousel-item">
          <img src="/assets/img/slider/slider2.jpg" alt="">
          <div class="carousel-caption text-center">
            <h2 class="wow fadeInDown" data-wow-delay="0.2s">Trabajo</h2>
            <h3 class="wow bounceIn" data-wow-delay="0.4s">Profesional y Personalizado</h3>
          </div>
        </div>
        <div class="carousel-item">
          <img src="/assets/img/slider/slider3.jpg" alt="">
          <div class="carousel-caption text-center">
            <h2 class="wow fadeInDown" data-wow-delay="0.3s">Soluciones</h2>
            <h3 class="wow fadeInRight" data-wow-delay="0.6s">Concretas y Eficientes</h3>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carousel-slider" role="button" data-slide="prev">
        <span class="carousel-control" aria-hidden="true"><i class="lni-chevron-left"></i></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carousel-slider" role="button" data-slide="next">
        <span class="carousel-control" aria-hidden="true"><i class="lni-chevron-right"></i></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</header>
<!-- Header Section End -->

<!-- Services Section Start -->
<section id="services" class="section">
  <div class="container">
    <div class="section-header">
      <h2 class="section-title">Nuestros Servicios</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="item-boxes services-item wow fadeInDown" data-wow-delay="0.2s">
          <div class="icon color-3">
            <i class="lni-ambulance"></i>
          </div>
          <h4>Accidentes de Trabajo</h4>
          <p>Reclamo ante Aseguradoras de Riesgos del Trabajo en materia de accidentes y enfermedades profesionales. Si sufrió un accidente y no conoce su ART, o su empleador o aseguradora no le da respuesta ¡Contáctenos!</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="item-boxes services-item wow fadeInDown" data-wow-delay="0.4s">
          <div class="icon color-3">
            <i class="lni-thumbs-down"></i>
          </div>
          <h4>Despidos</h4>
          <p>Asesoramiento integral en la materia. Despidos con causa e incausados. Confección de telegramas y liquidaciones correspondientes con inclusión de rubros salariales e indemnizatorios</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="item-boxes services-item wow fadeInDown" data-wow-delay="0.6s">
          <div class="icon color-3">
            <i class="lni-money-protection"></i>
          </div>
          <h4>Diferencia de Salarios</h4>
          <p>Atendemos reclamos en materia salarial de cualquier gremio o actividad. Practicamos liquidación del haber actualizado de acuerdo a las disposiciones del convenio colectivo aplicable y horas extras</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="item-boxes services-item wow fadeInDown" data-wow-delay="0.8s">
          <div class="icon color-3">
            <i class="lni-warning"></i>
          </div>
          <h4>Trabajo en Negro</h4>
          <p>Brindamos información de los derechos que asisten a todo trabajador sin registrar o registrado defectuosamente y lo asistimos en la protección de los mismos. Trabajadores bajo el régimen de la ley de contrato de trabajo, construcción, monotributistas, servicio doméstico, etc</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="item-boxes services-item wow fadeInDown" data-wow-delay="1s">
          <div class="icon color-3">
            <i class="lni-users"></i>
          </div>
          <h4>Asesoramiento a Empresas</h4>
          <p>Brindamos asesoramiento integral en materia laboral para empresas en etapa prejudicial, representación y negociación ante el SECLO. Representación y acompañamiento en procesos judiciales en el marco de la Ciudad Autónoma de Buenos Aires</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="item-boxes services-item wow fadeInDown" data-wow-delay="1.2s">
          <div class="icon color-3">
            <i class="lni-briefcase"></i>
          </div>
          <h4>Derecho Civil</h4>
          <p>También brindamos asesoramiento integral en accidentes de tránsito y en materia daños en general. Redacción de contratos. Acompañamiento y negociación en procesos de familia, divorcios y sucesiones</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Services Section End -->

<!-- Call To Action Section Start -->
<section id="cta" class="section" data-stellar-background-ratio="0.5">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-xs-12">
        <div class="cta-text">
          <h3>Conozca sus derechos</h3>
          <p>Nuestros estudio se especializa en el derecho laboral, tanto en relaciones laborales como accidentes de trabajo.</p>
          <p>Asesoramos trabajadores y empleadores de todas las actividades</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-xs-12 text-right">
        <a href="#contact" class="btn btn-border page-scroll">Contactanos</a>
      </div>
    </div>
  </div>
</section>
<!-- Call To Action Section Start -->

<!-- Team section Start -->
<section id="team" class="section">
  <div class="container">
    <div class="section-header">
      <h2 class="section-title">Nosotros</h2>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Somos un estudio jurídico multidisciplinario que actúa en diversas áreas del derecho. Formado por <strong>abogados de acreditada solvencia profesional</strong>, nuestra especialidad principal es el <strong>Derecho Laboral</strong>. También contamos con especialistas en materias como <strong>Derecho Civil, Derecho de Familia, y Derecho Previsional</strong>, por lo que la firma ofrece asesoramiento legal y asistencia letrada en todas las áreas del Derecho y en el marco de la Ciudad Autónoma de Buenos Aires</p>
        <br>
        <p><strong>Nuestro estudio presta sus servicios tanto a empresas como a particulares</strong>, adaptándose a las necesidades de cada tipología de cliente, ofreciendo un servicio de calidad, bajo los principios de eficacia, eficiencia y excelencia en el trato con el cliente</p>
        <br>
        <p>Somos plenamente conscientes de que cada cliente tiene unas necesidades propias y diferenciadas, por lo que procuramos ofrecerle soluciones a su medida a fin de ofrecerle el mejor servicio posible</p>
      </div>
    </div>
  </div>
</section>
<!-- Team section End -->

<!-- Work section Start -->
<section id="work" class="section">
  <div class="container">
    <div class="section-header">
      <h2 class="section-title">COMO TRABAJAMOS</h2>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Nuestros clientes son nuestra razón de ser y nuestro principal objetivo es proporcionarles un <strong>servicio de calidad</strong> que satisfaga todas sus expectativas. Ello no solo implica trabajar con entusiasmo para defender los intereses de nuestro cliente, sino también ofrecerle un <strong>trato humano y de proximidad</strong></p>
        <br>
        <p>Para ello, ofrecemos un <strong>trato personalizado</strong> a nuestros clientes, atendiendo a sus necesidades concretas y dando <strong>soluciones a medida</strong> para cada uno de ellos</p>
        <br>
        <p>Desde un primer momento informamos al cliente de los pasos a seguir, la estrategia a adoptar y las vicisitudes del procedimiento</p>
        <br>
        <p>El cliente debe estar informado en todo momento del estado de tramitación del expediente y, por ello, asumimos el <strong>compromiso de mantener permanentemente informados a nuestros clientes</strong> de cualquier avance que se produzca</p>
      </div>
    </div>
  </div>
</section>
<!-- Work section End -->

<!-- Map Section Start -->
<section id="google-map-area">
  <div class="container-fluid">
    <div class="section-header">
      <h2 class="section-title">Ubicación</h2>
      <p class="section-subtitle">Avenida San Pedrito 137, Flores, Ciudad de Buenos Aires (CP 1406), Argentina</p>
    </div>
    <div class="row">
      <div class="col-12 padding-0">
        <object style="border:0; height: 450px; width: 100%;" data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.8741887983833!2d-58.4691773!3d-34.6326195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc985ff68a3b7%3A0x503e1f7a61e0a001!2sEstudio+Jur%C3%ADdico+San+Pedrito!5e0!3m2!1ses-419!2sar!4v1560522386626!5m2!1ses-419!2sar"></object>
      </div>
    </div>
  </div>
</section>
<!-- Map Section End -->

<!-- Contact Section Start -->
<section id="contact" class="section">
  <div class="contact-form">
    <div class="container">
      <div class="section-header">
        <h2 class="section-title">Contactanos</h2>
      </div>
      <div class="row">
        <div class="col-lg-9 col-md-9 col-xs-12">
          <div class="contact-block">
            <!--<form id="contactForm"> -->

              <!-- reCaptcha v3 -->
              <input type="hidden" name="recaptcha_response" id="recaptchaResponse">

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" id="name" name="name" placeholder="Su Nombre" [(ngModel)]="form.nombre" >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Su Email" id="email" class="form-control" name="name" required data-error="Por favor, ingrese su email" [(ngModel)]="form.email">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" placeholder="Su Asunto" id="msg_subject" class="form-control" [(ngModel)]="form.asunto">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea class="form-control" id="message" placeholder="Su mensaje" rows="7" data-error="Por favor, ingrese su mensaje" required [(ngModel)]="form.mensaje"></textarea>
                  </div>
                  <div class="submit-button">
                    <button class="btn btn-common btn-effect" id="submit" type="submit" [ngClass]="{'disabled':!enviar}" (click)="submitForm()">Enviar consulta</button>
                  </div>
                </div>
              </div>
            <!--</form> -->
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-xs-12">
          <div class="contact-deatils">
            <!-- Content Info -->
            <div class="contact-info_area">
              <div class="contact-info">
                <i class="lni-map-marker" style="float: left;"></i>
                <h5>&nbsp;&nbsp;Ubicación:</h5>
                <p>Avenida San Pedrito 137, Ciudad de Buenos Aires (CP 1406), Argentina</p>
              </div>
              <!-- Content Info -->
              <div class="contact-info">
                <i class="lni-envelope" style="float: left;"></i>
                <h5>&nbsp;&nbsp;E-mail:</h5>
                <a href="mailto:estudiosanpedrito@gmail.com">estudiosanpedrito@gmail.com</a>
              </div>
              <!-- Content Info -->
              <div class="contact-info">
                <i class="lni-phone"  style="float: left;"></i>
                <h5>&nbsp;&nbsp;Telefonos:</h5>
                <a href="tel:+5491120632105">(011) 2063-2105</a>
                <br>
                <a href="tel:+5491136694805">(011) (15) 3669-4805</a>
              </div>
              <!-- Content Info -->
              <div class="contact-info">
                <h5>Horario de Atención:</h5>
                <p>Por emergencia sanitaria:</p>
                <p>Miércoles de 9.30 a 12.30 con turno.</p>
                <p>Atención las 24hs por whatsapp o e-mail.</p>
              </div>
              <!-- Icon -->
              <ul class="footer-social">
                <li><a class="facebook" href="https://www.facebook.com/trabajoydespido/" target="_blank"><i class="lni-facebook-filled"></i></a></li>
                <li><a class="linkedin" href="#"><i class="lni-linkedin-filled"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact Section End -->

<!-- Footer Section -->
<footer>
  <!-- Copyright Start  -->
  <div id="copyright">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="site-info float-left">
            <p>Creado por <a href="https://hatch.com.ar" rel="nofollow" target="_blank">Hatch</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Copyright End -->

</footer>
<!-- Footer Section End -->

<!-- Go To Top Link -->
<a href="#slider-area" class="back-to-top page-scroll">
  <i class="lni-arrow-up"></i>
</a>

<div id="loader">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>
<whatsapp-app></whatsapp-app>

